import styles from './Curso.module.css'
import React, { useEffect, useMemo, useState } from 'react'
import { Input, MenuDrawer, PnoWrap } from '../../components'
import { Environment, Head, schoolService } from '../../shared'
import {
  Box, Button, Drawer, Icon, LinearProgress, Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography, useTheme
} from '@mui/material'
import {  useSearchParams } from 'react-router-dom';
import { courseService } from '../../shared/services/course';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../shared/hooks';
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Select from '../../components/formComponents/Select'

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatõrio')
        .matches(/^[^0-9]+$/, 'Não é permitido valores numericos')
        .min(3, 'Precisa de pelomentos 4 caracter a área de formação'),
  description: yup.string().optional(),
  statu_id: yup.number().required('Campo obrigatõrio'),
})


export function TrainingArea() {
  const [data, setData] = React.useState([]);
  const { control, handleSubmit, formState: {errors}, setValue, reset } = useForm({resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      statu_id: 1,
    }
  });
  const [isUpdate, setIsUpdate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [IsSubmit, setIsSubmit] = React.useState(false);
  const [Change, setChange] = React.useState(false);
  const [totalAccount, setTotalAccount] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useAuthContext()
  const [status, setStatus] = useState([])
  const { palette } = useTheme();


  const search = useMemo(() => {
    return searchParams?.get('search') || ''
  }, [searchParams])

  const page = useMemo(() => {
    return searchParams?.get('page') || 1
  }, [searchParams])

  const setDataUpdate = function (id) {
    const objectUpdate = data.filter(el => el.id === id )[0];    
    setValue('name', objectUpdate?.name);
    setValue('description', objectUpdate?.description);
    setValue('statu_id', objectUpdate?.status.id);
  }
  
  const findAllCourses = async () => {
      try {
        setLoading(true);
        const { url, options } = courseService.GET_TRAINIG(page, Environment.ROW_LIMIT, search ?? '', token ?? '');
        const response = await fetch(url, options);
        const result = await response.json();
        console.log({result});
        
        setData(result.data??[]);
        setTotalAccount(result.meta.total)
      } catch (error) {
        if (error.message === 'Failed to fetch')
          toast.error('Problemas de conexão com a internet!')
        toast.error(error.message)

      } finally {
        setLoading(false);
      }
    }
    
    const findAllStatus = async () => {
      try {
        const response = await schoolService.getStatusSchool('',token ?? '');

        setStatus(response.data??[]);
      } catch (error) {
        if (error.message === 'Failed to fetch')
          toast.error('Problemas de conexão com a internet!')
        toast.error(error.message)

      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      findAllStatus()
    }, [])
    
  React.useEffect(() => {
    findAllCourses();
    setChange(false);
  }, [search, page, Change]);

  const toggleDrawer = (id = undefined) => {
    if(!id) {
      setOpenDrawer(!openDrawer)
    }else {
      setIsUpdate(id)
      setOpenDrawer(!openDrawer)
    }
  }

  const Submit = async (dataForm) => {
    setIsSubmit(true);
    try {
      const result = await courseService.POST_TRAINING(dataForm, token)

      if(result.message === "The name has already been taken."){
        toast.error('Área de formação já se encontra Cadastrada!!!')
        return;
      }

      setChange(true)
      toast.success('Area de formação cadastrada com sucesso.');
      setIsSubmit(false)
      toggleDrawer()
    } catch (error) {
      console.log({error});
    }
  }  

  const handleUpdate = async (dataForm) => {
    setIsSubmit(true)
    try {
      const result = await courseService.PUT_TRAINING(dataForm, isUpdate, token)

      if(result.message === "The name has already been taken."){
        toast.error('Área de formação já se encontra Cadastrada!!!')
        return;
      }

      setChange(true);
      toast.success('Area de formação Actualizada com sucesso.');
      setIsUpdate(null)
      setIsSubmit(false)
      toggleDrawer()
    } catch (error) {
      console.log({error});
    }
  }

  return (
    
    <MenuDrawer page='Área de Formação'>
      <Head title='Área de Formação' />
      <Box component={Paper} py={2} m={2} flex={1}>
        <Box m={1} ml={2}>
          <section className='bloco-pesquisa-botao'>
            <div className='search'>
              <input type='text' placeholder='Pesquisar'
                onChange={(evt) => { setSearchParams({ search: evt.target.value }, { replace: true }) }}
                value={search ?? ''} />
              <button><i className='fas fa-search'></i></button>
            </div>

            <Button className='btn btn-add' onClick={() => {toggleDrawer(); reset(); setIsUpdate(null)}}>
              Cadastrar  <i className='fas fa-plus ml-1'></i>
            </Button>
          </section>
          <Drawer anchor='right' open={openDrawer} variant='temporary'>  
            <Box width={300} pt={10} px={2}>
              <Box display={'flex'} justifyContent={'space-between'}>
                {isUpdate ? <PnoWrap color='black' text='Actualizar' />:<PnoWrap color='black' text='Cadastrar' />}

                <Icon onClick={() => toggleDrawer()}>close</Icon>
              </Box>

              <Box component={'form'} noValidate onSubmit={isUpdate ? handleSubmit(handleUpdate) : handleSubmit(Submit)}>
                <Box my={2}>
                  <Controller
                    control={control}
                    name='name'
                    render={({field: {value, onChange}}) => (
                      <Input label={'digite a área de formação'}
                        errorText={errors.name?.message} error={!!errors.name?.message} value={value}
                        onChange={(e) => onChange(e.target.value)}/>
                    )}
                  />
                </Box>
                

                <Box my={2}>
                  <Controller
                    control={control}
                    name='description'
                    render={({field: {value, onChange}}) => (
                      <textarea 
                        value={value}
                        onChange={(e) => onChange(e.target.value)}  
                        rows={14}    
                        placeholder='Digite a descrição da área de formação'                   
                      ></textarea>
                    )}
                  />
                </Box>
                <Box my={2}>
                  <Controller
                    control={control}
                    name='statu_id'
                    render={({field: {value, onChange}}) => (
                      <Select 
                        placeholder={'Selecione o estado'}
                        values={status}
                        errorText={errors.statu_id?.message} error={!!errors.statu_id?.message} value={value}
                        onChange={onChange}/>
                    )}
                  />
                </Box>
                {isUpdate ? <Button variant='contained' color='secondary' type='submit'>
                  {IsSubmit ? '...':'Actualizar'}
                </Button>:
                <Button variant='contained' type='submit'>
                  {IsSubmit ? '...':'Salvar'}
                </Button>}
              </Box>
            </Box>
          </Drawer>
          <Box marginBottom={5}>
            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
              <TableContainer component={Paper} elevation={0} sx={{ flexGrow: 1 }} style={{ border: '1px solid #ededed' }}>
                <Table sx={{ minWidth: 650, color: 'white' }} size='small' aria-label="a dense table">
                  <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: 'white' } }}>
                    <TableRow>
                      <TableCell className='title-table text-center'>Área de Formação</TableCell>
                      <TableCell className='title-table text-center'>Descrição</TableCell>
                      <TableCell className='title-table text-center'>Estado</TableCell>
                      <TableCell className='title-table text-center'>Acções</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.length > 0 ?
                      data.map((item) => (
                        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell className='text-center cel-name'>{item.name}</TableCell>
                          <TableCell className='text-center cel-name'>{item.description}</TableCell>
                          <TableCell className='text-center'>
                            
                              {item.status?.name === 'Ativo' && <p className='curso-activo'>{item.status?.name}</p>} 
                              {item.status?.name === 'Inactivo' && <p className='curso-inactivo'>{item.status?.name}</p>} 
                              {item.status?.name === 'Suspenso' && <p className='curso-inactivo'>{item.status?.name}</p>} 
                            
                          </TableCell>
                          <TableCell align="center">
                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                              <Typography className='btn-icon btn-edit' onClick={() => { setDataUpdate(item.id); toggleDrawer(item.id)}}>
                                <i className="fa-solid fa-edit"></i>
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )) : (
                        <TableRow>
                          <TableCell colSpan={12} align="center"><Typography width={250}>Lista de Cursos Vazia</Typography></TableCell>
                        </TableRow>
                      )
                    }

                    {loading && (
                      <TableRow>
                        <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                      </TableRow>)
                    }

                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                        <Pagination
                          count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                          page={Number(page)} onChange={(_, newPage) => { setSearchParams({ _, page: newPage.toString() }, { replace: true }) }}
                          color='primary'
                          size='small'
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </MenuDrawer>
  )
}
