import React from 'react';
import styles from './Card.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faCheckCircle, faPauseCircle, faClock } from '@fortawesome/free-solid-svg-icons';

const Card = ({
    totalEscolas,
    escolasAtivas,
    escolasInativas,
    escolasPendentes,
    customCards
}) => {
    const defaultCards = [
        { title: 'Total de x', number: totalEscolas, icon: faSchool, iconColor: '#E19E06' },
        { title: 'Escolas x', number: escolasAtivas, icon: faCheckCircle, iconColor: '#E19E06' },
        { title: 'Escolas x', number: escolasInativas, icon: faPauseCircle, iconColor: '#E19E06' },
        { title: 'Pendentes', number: escolasPendentes, icon: faClock, iconColor: '#E19E06' }
    ];

    const cardData = customCards || defaultCards;

    return (
        <section className={styles.cards}>
            {cardData.map((card, index) => (
                <div className={styles.card} key={index}>
                    <div className={styles.cardContent}>
                        <h2 className={styles.cardTitle}>{card.title}</h2>
                    </div>
                    <div className={styles.cardData}>
                        <h3 className={styles.cardNumber}>{card.number}</h3>
                        <FontAwesomeIcon
                            icon={card.icon}
                            className={styles.cardIcon}
                            style={{ color: card.iconColor || '#E19E06' }}
                        />
                    </div>
                </div>
            ))}
        </section>
    );
};

export default Card;
