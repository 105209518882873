import styles from './SeeMoreFunctionary.module.css'
import React, { useEffect, useState } from 'react'
import { Avatar } from '@mui/material'
import { Head, schoolService } from '../../shared'
import { MenuDrawer } from '../../components'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../shared/hooks'
import { toast } from 'react-toastify'



export function SeeMoreSchool() {
    const { id } = useParams();
    const { token } = useAuthContext()
    const [Status, setStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Change, setChange] = useState(false);
    const [schoolSeleted, setSchoolSeleted] = useState(null);
    const [schoolStatusSelected, setSchoolStatusSelected] = useState(null);
    const [schoolType, setSchoolType] = useState([]);



    const changeStatus = async function (status) {
        setLoading(true)
        const status_id = Status.filter((stat) => stat.name === status);
        const data = {
            school_ids: [id],
            statu_id: status_id[0].id,
            message: "Bla"
        }

        const response = await schoolService.put_status_school(data, token);

        if (response instanceof Error) {
            setLoading(false)
            return toast.error(response.errors.message);
        }

        if (response.success) {
            setLoading(false);
            setChange(!Change)
            return toast.success(response.message)
        }
        setLoading(false)
    }


    const getAllStatus = async () => {
        try {
            const { data } = await schoolService.getStatusSchool('', '');
            setStatus(data);
        } catch (error) {
            console.log(error);
        }
    }

    const findAllSchools = async (idSchool) => {
        try {
            setLoading(true);
            const { url, options } = schoolService.GET_school(1, 9999999999999, '', undefined, undefined, undefined, token ?? '')


            const response = await fetch(url, options)
            const result = await response.json();

            setSchoolStatusSelected(result.data.filter(school => school.id === Number(idSchool))[0].status.name)
            setSchoolSeleted(result.data.filter(school => school.id === Number(idSchool))[0]);
        } catch (error) {
            console.log('====================================');
            console.log(error);
            console.log('====================================');
        } finally {
            setLoading(false);
        }
    }

    const getSchoolType = async () => {
        try {
            const response = await schoolService.listSchoolType();
            setSchoolType(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    useEffect(() => {
        getAllStatus();
        getSchoolType()
    }, [])

    useEffect(() => {
        if (id) { findAllSchools(id) }
    }, [id, Change])

    const [activeTab, setActiveTab] = useState('overview');

    return (
        <MenuDrawer page='Escolas'>
            <Head title='Datalhes' />
            <main className={styles['school-detail']}>
                <h1 className={styles['school-detail__title']}>Detalhe da Escola</h1>

                <div className={styles['school-detail__header']}>
                    <img alt="Imagem da Escola" />
                    <div className={styles['school-detail__info']}>
                        <div className={styles['school-detail__info-data']}>
                            <div className={styles['school-detail__avatar']}>
                                <Avatar
                                    alt="Imagem da Escola"
                                    src={schoolSeleted?.image}
                                    sx={{ width: 86, height: 86 }}
                                />
                            </div>
                            <div>
                                <h2>Escola Primária Luanda</h2>
                                <p>
                                    <i className="fa-solid fa-location-dot"></i> Belas, Luanda</p>
                            </div>
                        </div>
                        <div>
                            <button className={`${styles['school-detail__action-btn']} ${styles['school-detail__action-btn--active']}`}>
                                <i className="fa-solid fa-check-double"></i> Ativo
                            </button>
                            <button className={styles['school-detail__action-btn']}>
                                Pública
                            </button>
                            <button className={styles['school-detail__action-btn']}>
                                Ensino Primário
                            </button>
                        </div>
                    </div>
                </div>

                <div className={styles['school-detail__options']}>
                    <button className={styles['school-detail__option-btn--suspend']}>
                        <i className="fa-solid fa-triangle-exclamation"></i> Suspender Escola
                    </button>
                    <button className={styles['school-detail__option-btn--delete']}>
                        <i className="fa-solid fa-trash-can"></i> Eliminar Escola
                    </button>
                    <button className={styles['school-detail__option-btn--edit']}>
                        <i className="fa-solid fa-pen-to-square"></i> Editar Escola
                    </button>
                </div>

                <nav className={styles['school-detail__tabs']}>
                    <button
                        className={`${styles['school-detail__tab-btn']} ${activeTab === 'overview' ? styles['school-detail__tab-btn--active'] : ''
                            }`}
                        onClick={() => setActiveTab('overview')}
                    >
                        Visão Geral
                    </button>
                    <button
                        className={`${styles['school-detail__tab-btn']} ${activeTab === 'statistics' ? styles['school-detail__tab-btn--active'] : ''
                            }`}
                        onClick={() => setActiveTab('statistics')}
                    >
                        Estatística da Escola
                    </button>
                </nav>

                {activeTab === 'overview' && (
                    <div className={styles['tab-content']}>
                        <div className={styles.overview_content}>
                            <section className={styles['school-info-section']}>

                                <h2 className={styles['shool-info-subtitle']} >Informações da Escola</h2>
                                <ul className={styles['school-info-list']}>
                                    <li className={styles['school-info-item']} >
                                        <h4>Número da Escola:</h4>
                                        <p className={styles['school-info-text']}>
                                            <i className="fa-solid fa-school" style={{ color: '#D18A06' }} ></i> 123456
                                        </p>
                                    </li>
                                    <li className={styles['school-info-item']}  >
                                        <h4>Tipo de Escola:</h4>
                                        <p className={styles['school-info-text']} >
                                            <i className="fa-solid fa-school" style={{ color: '#D18A06' }} ></i> Escola Primária
                                        </p>
                                    </li>
                                    <li className={styles['school-info-item']}  >
                                        <h4>Nível de Ensino:</h4>
                                        <p className={styles['school-info-text']} >
                                            <i className="fa-solid fa-book" style={{ color: '#D18A06' }} ></i> Ensino Primário
                                        </p>
                                    </li>
                                    <li className={styles['school-info-item']}  >
                                        <h4>Data de Registro:</h4>
                                        <p className={styles['school-info-text']} >
                                            <i className={`fa-solid fa-calendar`} style={{ color: '#D18A06' }} ></i> 01/01/2010
                                        </p>
                                    </li>
                                </ul>
                                <div className={styles['school-info-description']}  >
                                    <h4 className={styles['shool-info-description.title']} >Descrição:</h4>
                                    <p className={styles['school-info-description-text']} >
                                        Escola de referência na região, oferecendo
                                        educação de qualidade desde 2010.
                                    </p>
                                </div>
                                <div className={`${styles['school-info-detail']}`}  >
                                    <h4 className={`${styles['school-info-detail-title']}`} >
                                        Informações de Contato:
                                    </h4>

                                    <ul className={styles['school-info-detail-list']} >
                                        <li className={`${styles['school-info-detail-item']}`} >
                                            <h4>Email:</h4>
                                            <p className={`${styles['school-info-detail-text']}`} >
                                                <i className="fa-solid fa-envelope" style={{ color: '#D18A06' }} ></i> primaria.luanda@edu.ao
                                            </p>
                                        </li>
                                        <li className={`${styles['school-info-detail-item']}`} >
                                            <h4>Telefone:</h4>
                                            <p className={`${styles['school-info-detail-text']}`} >
                                                <i className="fa-solid fa-phone" style={{ color: '#D18A06' }} ></i> +244 923 456 789
                                            </p>
                                        </li>
                                        <li className={`${styles['school-info-detail-item']}`} >
                                            <h4>Endereço:</h4>
                                            <p className={`${styles['school-info-detail-text']}`} >
                                                <i className="fa-solid fa-location-dot" style={{ color: '#D18A06' }} ></i> Rua da Escola, Luanda
                                            </p>
                                        </li>
                                        <li className={`${styles['school-info-detail-item']}`} >
                                            <h4>Decreto</h4>
                                            <p className={`${styles['school-info-detail-text']}`} >
                                                <i className="fa-solid fa-file" style={{ color: '#D18A06' }} ></i> Decreto de Criação: 123/2010
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </section>

                            <section className={styles['director-section']}>
                                <h2>Diretor da Escola</h2>
                                <div className={styles['director-info']}>
                                    <Avatar
                                        alt="Imagem do Diretor"
                                        src={schoolSeleted?.image}
                                        sx={{ width: 86, height: 86 }}
                                        className={styles['director-avatar']}
                                    />
                                    <ul className={styles['director-info-list']}>
                                        <li className={styles['director-info-item']}>
                                            <h4>Nome:</h4>
                                            <p className={styles['director-info-text']}>
                                                <i className="fa-solid fa-user" style={{ color: '#D18A06' }} ></i> João Silva
                                            </p>
                                        </li>
                                        <li className={styles['director-info-item']}>
                                            <h4>Cargo:</h4>
                                            <p className={styles['director-info-text']}>
                                                <i className="fa-solid fa-user-tie" style={{ color: '#D18A06' }} ></i> Diretor
                                            </p>
                                        </li >
                                        <li className={styles['director-info-item']}>
                                            <h4>Email:</h4>
                                            <p className={styles['director-info-text']}>
                                                <i className="fa-solid fa-envelope" style={{ color: '#D18A06' }} ></i> joao.silva@edu.ao
                                            </p>
                                        </li>
                                        <li className={styles['director-info-item']}>
                                            <h4>Contato:</h4>
                                            <p className={styles['director-info-tex']}>
                                                <i className="fa-solid fa-phone" style={{ color: '#D18A06' }} ></i> +244 923 456 789
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                        </div>

                        <div className={styles['overview-cards']}>
                            <div className={styles['overview-card']}>
                                <i className="fa-solid fa-user-graduate"></i>
                                <p>Alunos</p>
                                <h3>450</h3>
                            </div>
                            <div className={styles['overview-card']}>
                                <i className="fa-solid fa-chalkboard-teacher"></i>
                                <p>Professores</p>
                                <h3>28</h3>
                            </div>
                            <div className={styles['overview-card']}>
                                <i className="fa-solid fa-door-open"></i>
                                <p>Salas de Aula</p>
                                <h3>24</h3>
                            </div>
                            <div className={styles['overview-card']}>
                                <i className="fa-solid fa-users"></i>
                                <p>Relação Aluno/Professor</p>
                                <h3>16.1</h3>
                            </div>
                        </div>
                    </div>
                )}

                {activeTab === 'statistics' && (
                    <div className={styles['tab-content']}>
                        <h2>Estatística da Escola</h2>
                        <p>Aqui serão exibidas as estatísticas detalhadas da escola.</p>
                        {/* Adicione aqui os gráficos ou dados estatísticos conforme necessário */}

                    </div>
                )}



            </main>

        </MenuDrawer>
    )
}
