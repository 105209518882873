// styles
//import styles from './add_escola.module.css';
import styles from '../../pages/escola/EscolaRegister.module.css';
// dependencies
import * as yup from 'yup';
import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, Container, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material'
import { styled } from '@mui/material/styles';
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Input } from '../form';
import { functionaryServices, Head, inscription_services, schoolService, usersServices } from '../../shared'
import { MenuDrawer } from '../Dashboard';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
    name: yup.string().required().min(2),
    school_type_id: yup.number().required(),
    level_education_id: yup.number().optional(),
    email: yup.string().required(),
    province_id: yup.number().required().moreThan(0),
    municipality_id: yup.number().required(),
    decree_creation: yup.string().notRequired(),
    reference_point: yup.string().optional(),
    school_number: yup.number().optional(),
    logo: yup.mixed().optional().test('fileType', 'Tipo de arquivo inválido. Apenas .jpg, .jpeg, .png são permitidos.', (value) => {
        return value && ['image/jpeg', 'image/png'].includes(value.type);
      })
    //location_id: yup.number().optional().moreThan(0),
   // public_school_id: yup.number().optional(),
})

export function AddAndUpdateEsola() {
    const [form, setForm] = React.useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([])
    const { control, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            name: '',
            logo: undefined,
            school_type_id: undefined,
            level_education_id: undefined,
            province_id: undefined,
            municipality_id: undefined,
            reference_point: '',
            email: '',
            decree_creation: null,
            school_number: undefined,
            location_id: undefined,
            public_school_id: undefined
        }
    })

    const schoolTypeChange = watch('school_type_id');
    const [schools, setsShools] = useState(null);
    const [level, setLevel] = useState([]);
    const [schoolType, setSchoolType] = useState([]);
    const [municipe, setMunicipe] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [schoolPublic, setSchoolPublic] = React.useState([]);
    const [province, setProvince] = useState([]);
    // const [Users, setUsers] = useState([]);
    const { token } = useAuthContext()

    const getSchoolType = async () => {
        try {
            const response = await schoolService.listSchoolType(token);
            setSchoolType(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const getSchoolMunicipe = async () => {
        try {
            const response = await schoolService.listSchoolMunicipe(token);
            setMunicipe(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const getSchoolLevel = async () => {
        try {
            const response = await schoolService.listSchoolLevel(token);
            setLevel(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const findAllSchools = async () => {
        try {
            const { url, options } = schoolService.GET_school(undefined, 100000000, 'publico', token ?? '')
            const response = await fetch(url, options);
            const result = await response.json();
            setSchoolPublic(result.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (id && typeof Number(id) === 'number') {
            setIsUpdate(true)
            schoolService.getOneSchool(id).then((data) => {
                setsShools(data.data);
            }).catch(err => {
                console.log({ err });
            })
        }
        getSchoolType();
        getSchoolMunicipe();
        getSchoolLevel();
        findAllSchools();
    }, []);

    useEffect(() => {
        if (schools) {
            setValue('province_id', schools?.['Provincia angolana']?.id);
            setValue('name', schools.name);
            setValue('municipality_id', schools.municipality_id);
            setValue('level_education_id', schools.level_education_id);
            setValue('school_type_id', schools.school_type_id);
            setValue('email', schools.email);
            setValue('decree_creation', schools.decree_creation);
            setValue('school_number', schools.school_number);
            setValue('public_school_id', schools.public_school_id);
            setValue('reference_point', schools.reference_point);
        }
    }, [schools]);

    const submitData = async (data) => {
        setLoading(true)
        delete data.country_id;
        try {
            const response = await schoolService.POST_school({ ...data, sort_order: 1, code: '1' },token);
            console.log({ response });
            if (response.error) {
                return toast.error(response.error.message || 'Problemas ao cadastrar Escola');
            }
            if (response.errors && response.message) {
                return toast.error(response.message || 'Problemas ao cadastrar Escola');
            }
            toast.success('Escola cadastrada com sucesso')
            navigate('/academic/school')
        } catch (error) {
            return toast.success('Problemas ao cadastrar a Escola');
        } finally {
            setLoading(false)
        }
    }

    const submitUpdate = async (data) => {
        setLoading(true)
        delete data.country_id;
        try {
            const response = await schoolService.updateSchool(id, data, token);
            if (response.error) {
                return toast.success('Problemas ao actualizar a Escola');
            }
            console.log({ response });
            if (response.data) {
                toast.success('Escola actualizada com sucesso')
                return navigate('/academic/school')
            }
            return toast.error('Problemas ao actualizar a Escola');
        } catch (error) {
            console.error({ error })
            return toast.error('Problemas ao actualizar a Escola');
        } finally {
            setLoading(false)
        }
    }

    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllCountry();
            setCountries(data);
        } catch (error) {
            console.log(error);
        }
    }

    // const getUsers = async () => {
    //     try {
    //         const { data } = await usersServices.getAll(token);
    //         setUsers(data ?? [])
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const handleSelectCountry = async (id) => {
        try {
            const { data } = await inscription_services.getProvinceForCountry(6);
            console.log({ data }, ' \n province');
            setProvince(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleSelectCountry()
        getCountries()
        // getUsers();
    }, [])

    const generoOptions = [
        { id: 1, name: 'Masculino' },
        { id: 2, name: 'Femenino' },
    ];

    return (
        <MenuDrawer page='Escolas'>
            <Head title={id ? 'Actualizar dados do Disciplina' : 'Cadastra Escola'} />
            <React.Fragment>
                <Head title='Registar Escola' />
                <main className={styles.register}>
                    <h1 className='title'>Cadastrar Escola</h1>
                    <form>
                        {form ? (
                            <section>
                                <p className={styles.subtitle}>Preencha todos os dados da escola</p>
                                <div className={styles.blockInput}>
                                    <Controller
                                        control={control}
                                        name='name'
                                        render={({field: {value, onChange}}) => (
                                            <Input label='Nome da Escola' value={value} onChange={onChange} id='name' name='name' placeholder='Digite o nome da escola' />
                                           
                                            )}
                                        /> 
                                    <Controller
                                        control={control}
                                        name='logo'
                                        render={({field: {value, onChange}}) => (
                                            <Input label='Logotipo' value={value} onChange={onChange} type='file' id='logo' name='logo' placeholder='Digite o nome da província' />
                                        )}
                                    /> 
                                    </div>
                                <div className={styles.blockInput}> 
                                    <Controller
                                        control={control}
                                        name='school_type_id'
                                        render={({field: {value, onChange}}) => (
                                            <Select label='Tipo de Escola' value={value} onChange={onChange} id='type' name='type' placeholder='Selecione o tipo de escola'
                                                values={schoolType}
                                            />
                                        )}
                                    /> 
                                    <Controller
                                        control={control}
                                        name='province_id'
                                        render={({field: {value, onChange}}) => (
                                            <Select label='Província' id='provincia' value={value} onChange={onChange}  name='provincia' values={province} placeholder='Selecione a provínciaa'/>
                                        )}
                                    /> 
                                </div>
                                <div className={styles.blockInput}>
                                    <Input label='Município' id='municipio' name='municipio' placeholder='Digite o nome do município' />
                                    <Select label='Nível de Ensino' id='nivel' name='nivel' placeholder='Selecione o nível de ensino'
                                        values={level}
                                    />
                                </div>
                                <div className={styles.blockInput}>
                                    <Input label='Decreto de Criação' id='decreto' name='decreto' placeholder='Digite o decreto de criação' />
                                    <Input label='Número da Escola' id='numero' name='numero' placeholder='Digite o número da escola' />
                                </div>
                                <div className={styles.blockInput}>
                                    <Input label='Ponto de Referência' id='referencia' name='referencia' placeholder='Digite o ponto de referência' />
                                    <Input label='Email da Escola' type='email' id='email' name='email' placeholder='Digite o email da escola' />
                                </div>
                                <Button onClick={() => setForm(false)}>Seguinte</Button>
                            </section>
                        ) :
                            (
                                <section>
                                    <p className={styles.subtitle}>Preencha todos os dados do director</p>
                                    <Input label='Nome do Director' id='director' name='director' placeholder='Digite o nome do director' />
                                    <div className={styles.blockInput}>
                                        <Input label='Imagem' type='file' id='img_director' name='img_director' placeholder='Digite o nome do director' />
                                        <Input label='Número do BI' id='bi' name='bi' placeholder='Digite o número do bilhete de identidade' />
                                    </div>
                                    <div className={styles.blockInput}>
                                        <Input label='Data de Nascimento' type='date' id='nascimento' name='nascimento' placeholder='Digite o nome do director' />
                                        <Select label='Gênero' id='genero' name='genero' placeholder='Selecione o gênero'
                                            values={generoOptions}
                                        />
                                    </div>
                                    <div className={styles.blockBtn}>
                                        <Button onClick={() => setForm(true)}>Voltar</Button>
                                        <Button type='submit'>Registar</Button>
                                    </div>
                                </section>
                            )
                        }
                    </form>
                </main>
            </React.Fragment>
        </MenuDrawer>
    )
}