// EscolaRegister.validation.js
import * as yup from 'yup';

export const escolaRegisterSchema = yup.object().shape({
    name: yup
        .string()
        .required('Campo nome da escola obrigatório')
        .nonNullable('Precisa digitar o nome da escola'),
    school_type_id: yup
        .number()
        .transform((value, originalValue) => originalValue === '' ? undefined : value)
        .required('Campo obrigatório'),
    level_education_id: yup
        .number()
        .transform((value, originalValue) => originalValue === '' ? undefined : value)
        .required('Campo obrigatório'),
    province_id: yup
        .number()
        .transform((value, originalValue) => originalValue === '' ? undefined : value)
        .required('Campo obrigatório')
        .moreThan(0, 'Província inválida'),
    municipality_id: yup
        .number()
        .transform((value, originalValue) => originalValue === '' ? undefined : value)
        .required('Campo obrigatório'),
    reference_point: yup
        .string()
        .required('Campo obrigatório'),
    email: yup
        .string()
        .required('Campo obrigatório')
        .email('Formato de email inválido.'),
    decree_creation: yup.string().when('school_type_id', ([school_type_id], schema) => {
        return school_type_id !== 3
            ? schema.required('Campo obrigatório')
            : schema.notRequired();
    }),
    school_number: yup.number().transform((value, originalValue) =>
        originalValue === '' ? undefined : value
    ).when('school_type_id', ([school_type_id], schema) => {
        return school_type_id !== 3
            ? schema.required('Campo obrigatório')
            : schema.notRequired();
    }),

    name_director: yup
        .string()
        .required('Campo obrigatório'),
    document_director: yup
        .string()
        .required('Campo obrigatório')
        .matches(/(^[\d]{9}\w{2}\d{3}$)/, 'Formato do BI inválido'),
    birthdate_director: yup
        .string()
        .required('Campo obrigatório'),
    gender_director: yup
        .string()
        .required('Campo obrigatório'),
    img_director: yup.mixed().optional(),
    public_school_id: yup.number().when('school_type_id', ([school_type_id], schema) => {
        return school_type_id === 3
            ? schema.transform((value, originalValue) => originalValue === '' ? undefined : value)
                .required('Campo obrigatório')
            : schema.notRequired();
    }),
});
