import styles from './Curso.module.css'
import React, { useMemo } from 'react'
import { MenuDrawer } from '../../components'
import { Environment, Head } from '../../shared'
import {
  Box, Divider, Grid, IconButton, InputBase, LinearProgress, Pagination,
  Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
  Typography, useTheme
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { courseService, GET_COURSES } from '../../shared/services/course';
import { toast } from 'react-toastify';
import { useAuthContext } from '../../shared/hooks';
import MoreCourse from '../../components/Modals/MoreCourse';
import { faGraduationCap, faCheckCircle, faTimesCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

import Container from '../../components/New/Container';
import TitleSection from '../../components/New/TitleSection.jsx';
import Cards from '../../components/New/Cards.jsx'
import Toolbar from '../../components/New/Toolbar.jsx';

import CourseDetailModal from '../../components/Curso/CourseDetailModal.jsx';

import { useEffect, useState } from 'react';

export function Course() {

  const [totalAccount, setTotalAccount] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const dummyData = [
      { id: 1, status: { name: 'active' } },
      { id: 2, status: { name: 'inactive' } },
      { id: 3, status: { name: 'active' } },
      { id: 4, status: { name: 'pending' } },
    ];
    setData(dummyData);
    setTotalAccount(dummyData.length);
  }, []);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setCourses([
        {
          id: 1,
          name: 'Curso de Matemática',
          description: 'Curso abrangente de matemática, desde álgebra até cálculo avançado.',
          status: 'Ativo',
          area: 'Exatas'
        },
        {
          id: 2,
          name: 'Curso de História',
          description: 'Estudo dos eventos históricos que moldaram o mundo.',
          status: 'Inativo',
          area: 'Humanas'
        },
        {
          id: 3,
          name: 'Curso de Biologia',
          description: 'Introdução à biologia e estudos sobre a vida.',
          status: 'Ativo',
          area: 'Ciências Biológicas'
        },
        {
          id: 4,
          name: 'Curso de Química',
          description: 'Fundamentos da química e suas aplicações práticas.',
          status: 'Pendente',
          area: 'Exatas'
        }
      ]);
      setLoading(false);
    }, 1000);
  }, []);

  const handleDeleteCourse = (id) => {
    if (window.confirm('Tem certeza de que deseja excluir este curso?')) {
      setCourses(prev => prev.filter(course => course.id !== id));
      console.log('Curso excluído:', id);
    }
  };


  const getStatusColor = (status) => {
    switch (status) {
      case 'Ativo':
        return '#28a745';
      case 'Inativo':
        return '#dc3545';
      case 'Pendente':
        return '#ffc107';
      default:
        return '#000';
    }
  };

  return (
    <MenuDrawer page='Cursos'>
      <Head title='Cursos' />

      <Container>
        <TitleSection
          title='Cursos'
          text='Gerencie todas os cursos cadastradas no sistema.'
        />

        <Cards
          totalEscolas={totalAccount}
          escolasAtivas={data.filter((item) => item.status.name === 'active').length}
          escolasInativas={data.filter((item) => item.status.name === 'inactive').length}
          escolasPendentes={data.filter((item) => item.status.name === 'pending').length}
          customCards={[
            { title: 'Total de Cursos', number: totalAccount, icon: faGraduationCap, iconColor: '#E19E06' },
            { title: 'Cursos Ativos', number: data.filter((item) => item.status.name === 'active').length, icon: faCheckCircle, iconColor: '#28a745' },
            { title: 'Cursos Inativos', number: data.filter((item) => item.status.name === 'inactive').length, icon: faTimesCircle, iconColor: '#dc3545' },
            { title: 'Pendentes', number: data.filter((item) => item.status.name === 'pending').length, icon: faHourglassHalf, iconColor: '#ffc107' }
          ]}
        />

        <Toolbar
          search={''}
          onSearchChange={() => { }}
          onAddClick={() => { }}
          onFilterClick={() => { }}
          onRecycleClick={() => { }}
          disableRecycle={false}
          searchPlaceholder='Pesquisar por nome, número ou diretor, email, etc...'
        />

        <TableContainer
          component={Paper}
          elevation={3}
          sx={{
            flexGrow: 1,
            borderRadius: 2,
            overflow: 'hidden',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
          }}
        >
          <Table sx={{ minWidth: 650 }} size="small" aria-label="Tabela de Cursos">
            <TableHead sx={{ backgroundColor: '#E19E06' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>ID Curso</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Nome do Curso</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Descrição</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Estado</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Área de Formação</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Acções</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.length > 0 ? (
                courses.map((course) => (
                  <TableRow
                    key={course.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: 'white',
                      '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' },
                      transition: 'background-color 0.3s',
                      '&:hover': { backgroundColor: '#f1f1f1' }
                    }}
                  >
                    <TableCell sx={{ textAlign: 'left' }}>{course.id}</TableCell>
                    <TableCell sx={{ textAlign: 'left' }}>{course.name}</TableCell>
                    <TableCell sx={{ textAlign: 'left' }}>{course.description}</TableCell>
                    <TableCell sx={{ textAlign: 'left' }}>
                      <Typography
                        sx={{
                          color: getStatusColor(course.status),
                          fontWeight: 'regular',
                        }}
                      >
                        {course.status}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left' }}>{course.area}</TableCell>
                    <TableCell sx={{ textAlign: 'left' }}>
                      <Box display="flex" gap={1} justifyContent="flex-start">
                        <Link className="btn-icon" to={`/academic/courses/info/${course.id}`}>
                          <i className="fa-solid fa-eye"></i>
                        </Link>
                        <Link to={`/academic/school/update/${course.id}`}>
                          <Typography className="btn-icon btn-edit">
                            <i className="fa-solid fa-edit"></i>
                          </Typography>
                        </Link>
                        <Typography
                          className="btn-icon btn-delete"
                          onClick={() => {
                            window.confirm('Tem certeza de que deseja excluir esta escola?')
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography>Lista de Cursos vazia</Typography>
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <LinearProgress color="secondary" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ pb: 2 }}>
                  <Pagination
                    count={Math.ceil(courses.length / 10)}
                    page={1}
                    onChange={() => { }}
                    color="primary"
                    size="small"
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>


      </Container>


    </MenuDrawer>
  )
}
