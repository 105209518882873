import React from 'react';

export default function Container({ children, color = '#FFFFFF', style, ...props }) {
    const containerStyle = {
        width: '100%',
        minHeight: '100vh',
        padding: '2rem 4rem',
        backgroundColor: color,
        ...style,
    };

    return (
        <div style={containerStyle} {...props}>
            {children}
        </div>
    );
}
