import styles from './Select.module.css'
import React from 'react'

const Select = ({ id, name, values, label, onChange, value, errorMessage, placeholder }) => {
    return (
        <div className={styles.wrapper}>
            <label htmlFor={id}>{label}</label>
            <select id={id} name={name} className={!!errorMessage ? styles.errorMessageBorder: undefined} onChange={onChange} value={value ?? ''}>
                <option value={''}>{placeholder}</option>
                {values && values.map((item) => (
                    <option value={item.id} key={item.id}>{item.name}</option>
                ))}
            </select>            
            {!!errorMessage && <small className={styles.errorMessage}>{errorMessage}</small>}
        </div>
    )
}

export default Select
