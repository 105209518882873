import React from 'react';
import styles from './Toolbar.module.css';

const Toolbar = ({
    search,
    onSearchChange,
    onAddClick,
    onFilterClick,
    onRecycleClick,
    disableRecycle = false,
    searchPlaceholder = 'Pesquisar por nome, número ou diretor, email, etc...',
    additionalButtons = [] 
}) => {
    return (
        <section className={styles.toolbar}>
            <div className={styles.searchBar}>
                <button className={styles.button}>
                    <i className="fas fa-search"></i>
                </button>
                <input
                    type="text"
                    placeholder={searchPlaceholder}
                    onChange={onSearchChange}
                    value={search || ''}
                    className={styles.search}
                />
            </div>
            <div className={styles.contentBtns}>
                <button
                    className={`${styles.actionButton} ${styles.addSchool}`}
                    onClick={onAddClick}
                >
                    Adicionar Escola <i className="fas fa-plus ml-1"></i>
                </button>
                <button
                    className={`${styles.actionButton} ${styles.filterSchools}`}
                    onClick={onFilterClick}
                >
                    Filtrar <i className="fas fa-filter ml-1"></i>
                </button>
                <button
                    className={`${styles.actionButton} ${styles.deleteSchool}`}
                    onClick={onRecycleClick}
                    disabled={disableRecycle}
                >
                    Reciclagem <i className="fas fa-trash ml-1"></i>
                </button>
                {additionalButtons.map((btn, index) => (
                    <button
                        key={index}
                        className={`${styles.actionButton} ${btn.className || ''}`}
                        onClick={btn.onClick}
                        disabled={btn.disabled}
                    >
                        {btn.label} {btn.icon && <i className={btn.icon}></i>}
                    </button>
                ))}
            </div>
        </section>
    );
};

export default Toolbar;
