// EscolaRegister.jsx
import React, { useEffect, useState } from 'react';
import styles from './EscolaRegister.module.css';
import { Head, inscription_services, schoolService } from '../../shared';
import Header from '../../components/Header';
import Input from '../../components/formComponents/Input';
import Button from '../../components/formComponents/Button';
import Select from '../../components/formComponents/Select';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { escolaRegisterSchema } from '../../shared/validations/EscolaRegister.validation';
import { FaCheckCircle, FaChartBar } from 'react-icons/fa';
import logo from '../../assets/images/image-logo-copy.png';

const EscolaRegister = () => {
    const navigate = useNavigate();
    const {
        control,
        handleSubmit,
        trigger,
        formState: { errors },
        watch,
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(escolaRegisterSchema),
        defaultValues: {
            name: '',
            img_director: undefined,
            school_type_id: '',
            level_education_id: '',
            province_id: '',
            municipality_id: '',
            reference_point: '',
            email: '',
            decree_creation: '',
            school_number: '',
            public_school_id: '',
            name_director: '',
            document_director: '',
            birthdate_director: '',
            gender_director: '',
            bi_directors: '',
        }
    });

    const [currentStep, setCurrentStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [changeFlag, setChangeFlag] = useState(false);
    const [level, setLevel] = useState([]);
    const [schoolType, setSchoolType] = useState([]);
    const [municipe, setMunicipe] = useState([]);
    const [schoolPublic, setSchoolPublic] = useState([]);
    const [province, setProvince] = useState([]);
    const [generoOptions] = useState([
        { id: 1, name: 'Masculino' },
        { id: 2, name: 'Feminino' },
        { id: 3, name: 'Outro' },
    ]);

    const provinceSelected = watch('province_id');

    const getSchoolType = async () => {
        try {
            const response = await schoolService.listSchoolType();
            setSchoolType(response.data);
        } catch (error) {
            console.error('Erro ao buscar tipos de escola:', error);
        }
    };

    const getSchoolMunicipe = async () => {
        try {
            const response = await schoolService.listSchoolMunicipe(provinceSelected || '');
            setMunicipe(response.data);
        } catch (error) {
            console.error('Erro ao buscar municípios:', error);
        }
    };

    const getSchoolLevel = async () => {
        try {
            const response = await schoolService.listSchoolLevel();
            setLevel(response.data);
        } catch (error) {
            console.error('Erro ao buscar níveis de ensino:', error);
        }
    };

    const findAllSchools = async () => {
        try {
            const { url, options } = schoolService.GET_school(undefined, 100000000, '', 1, 1, '');
            const response = await fetch(url, options);
            const result = await response.json();
            setSchoolPublic(result.data);
        } catch (error) {
            console.error('Erro ao buscar escolas públicas:', error);
        }
    };

    const loadProvinces = async () => {
        try {
            const { data } = await inscription_services.getProvinceForCountry(6);
            setProvince(data);
        } catch (error) {
            console.error('Erro ao buscar províncias:', error);
        }
    };

    useEffect(() => {
        getSchoolType();
        getSchoolMunicipe();
        getSchoolLevel();
        findAllSchools();
    }, [provinceSelected]);

    useEffect(() => {
        loadProvinces();
    }, []);

    const setExistValues = (v) => {
        Object.keys(v).forEach((key) => {
            setValue(key, v[key]);
        });
    };

    useEffect(() => {
        const savedData = localStorage.getItem('SCHOOL_REGISTER');
        if (savedData) {
            setExistValues(JSON.parse(savedData));
        }
    }, [changeFlag]);

    const handleNextStep = async () => {
        const fieldsStep1 = [
            'name',
            'school_type_id',
            'province_id',
            'municipality_id',
            'level_education_id',
            'reference_point',
            'email',
            'decree_creation',
            'school_number',
        ];
        const isValid = await trigger(fieldsStep1);
        if (isValid) {
            setCurrentStep(2);
        } else {
            toast.error('Por favor, preencha corretamente os campos da etapa atual.');
        }
    };

    const submitData = async (data) => {
        if (data.school_type_id === 3) {
            delete data.decree_creation;
            delete data.school_number;
        }
        setIsLoading(true);
        localStorage.setItem('SCHOOL_REGISTER', JSON.stringify(data));
        const response = await schoolService.POST_school(data);
        if (response.error || response instanceof Error || response.errors) {
            setIsLoading(false);
            setChangeFlag(!changeFlag);
            return toast.error(response.error?.message || response.message || 'Problemas ao cadastrar Escola');
        }
        if (response.success) {
            toast.success('Escola cadastrada com sucesso');
            reset();
            setIsLoading(false);
            localStorage.removeItem('SCHOOL_REGISTER');
            return navigate('/');
        }
        setChangeFlag(!changeFlag);
        setIsLoading(false);
        toast.error('Problema Interno, tente dentro de 5 minutos!');
    };

    return (
        <>
            <Head title="Registar Escola" />
            <Header />
            <main className={styles.container}>
                <section className={styles.contentImage}>
                    <div className={styles.contentImageContainer}>
                        <div className={styles.contentImageLogo}>
                            <img width="80px" src={logo} alt="Logo do Sistema" />
                        </div>
                        <h1 className={styles.contentImageTitle}>Sistema de Gestão Escolar</h1>
                        <p className={styles.contentImageText}>
                            Cadastre sua instituição e tenha acesso a ferramentas modernas de gestão educacional.
                        </p>
                        <ul className={styles.contentImageList}>
                            <li className={styles.contentImageListItem}>
                                <FaCheckCircle className={styles.icon} />
                                Gestão Simplificada
                            </li>
                            <li className={styles.contentImageListItem}>
                                <FaChartBar className={styles.icon} />
                                Relatórios Detalhados
                            </li>
                        </ul>
                    </div>
                </section>

                <form noValidate onSubmit={handleSubmit(submitData)} className={styles.form}>
                    <section className={styles.formContainer}>
                        <h1 className={styles.formTitle}>Registo de Escola</h1>
                        <p className={styles.paragrafo}>
                            Preencha os dados necessários para registrar sua instituição.
                        </p>

                        {currentStep === 1 && (
                            <div className={styles.contentForm}>
                                <div>
                                    <h2 className={styles.formSubTitle}>Dados da Escola</h2>
                                    <p>Informe os dados básicos da instituição</p>
                                </div>
                                <div className={styles.contentGroupForms}>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                label="Nome da Escola"
                                                type="text"
                                                id="name"
                                                placeholder="Digite o nome da escola"
                                                value={value}
                                                onChange={(e) => onChange(e.target.value)}
                                                errorMessage={errors.name?.message}
                                                aria-invalid={!!errors.name}
                                            />
                                        )}
                                    />
                                    <div className={styles.formGroupItem}>
                                        <Controller
                                            name="school_type_id"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    label="Tipo de Escola"
                                                    id="school_type_id"
                                                    placeholder="Selecione o tipo de escola"
                                                    values={schoolType}
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value === '' ? '' : Number(e.target.value))}
                                                    errorMessage={errors.school_type_id?.message}
                                                    aria-invalid={!!errors.school_type_id}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="province_id"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    label="Província"
                                                    id="province_id"
                                                    placeholder="Selecione a província"
                                                    values={province}
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value === '' ? '' : Number(e.target.value))}
                                                    errorMessage={errors.province_id?.message}
                                                    aria-invalid={!!errors.province_id}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className={styles.formGroupItem}>
                                        <Controller
                                            name="municipality_id"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    label="Município"
                                                    id="municipality_id"
                                                    placeholder="Selecione o município"
                                                    values={municipe}
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value === '' ? '' : Number(e.target.value))}
                                                    errorMessage={errors.municipality_id?.message}
                                                    aria-invalid={!!errors.municipality_id}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="level_education_id"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select
                                                    label="Nível de Ensino"
                                                    id="level_education_id"
                                                    placeholder="Selecione o nível de ensino"
                                                    values={level}
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value === '' ? '' : Number(e.target.value))}
                                                    errorMessage={errors.level_education_id?.message}
                                                    aria-invalid={!!errors.level_education_id}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.contentGroupForms}>
                                    <div className={styles.formGroupItem}>
                                        <Controller
                                            name="reference_point"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    label="Ponto de Referência"
                                                    type="text"
                                                    id="reference_point"
                                                    placeholder="Digite o ponto de referência"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    errorMessage={errors.reference_point?.message}
                                                    aria-invalid={!!errors.reference_point}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    label="Email"
                                                    type="email"
                                                    id="email"
                                                    placeholder="Digite o email da escola"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    errorMessage={errors.email?.message}
                                                    aria-invalid={!!errors.email}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className={styles.formGroupItem}>
                                        <Controller
                                            name="decree_creation"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    label="Decreto de Criação"
                                                    type="text"
                                                    id="decree_creation"
                                                    placeholder="Digite o decreto de criação"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    errorMessage={errors.decree_creation?.message}
                                                    aria-invalid={!!errors.decree_creation}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="school_number"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    label="Número da Escola"
                                                    type="text"
                                                    id="school_number"
                                                    placeholder="Digite o número da escola"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    errorMessage={errors.school_number?.message}
                                                    aria-invalid={!!errors.school_number}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <Button onClick={handleNextStep} type="button">
                                    Próximo
                                </Button>
                            </div>
                        )}

                        {currentStep === 2 && (
                            <div className={styles.contentForm}>
                                <div>
                                    <h2 className={styles.formSubTitle}>Dados do Diretor</h2>
                                    <p className={styles.formText}>
                                        Preencha as informações do responsável pela instituição
                                    </p>
                                </div>



                                <div className={styles.contentGroupForms}>
                                    <Controller
                                        name="name_director"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <Input
                                                label="Nome do Diretor"
                                                type="text"
                                                id="name_director"
                                                placeholder="Digite o nome do diretor"
                                                value={value}
                                                onChange={(e) => onChange(e.target.value)}
                                                errorMessage={errors.name_director?.message}
                                                aria-invalid={!!errors.name_director}
                                            />
                                        )}
                                    />
                                    <div className={styles.formGroupItem}>
                                        <Controller
                                            name='bi_directors'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    label="Nº do BI"
                                                    type="text"
                                                    id="bi_directors"
                                                    placeholder="Digite o número do BI"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    errorMessage={errors.bi_directors?.message}
                                                    aria-invalid={!!errors.bi_directors}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='img_director'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    label="Foto do Diretor"
                                                    type="file"
                                                    id="img_director"
                                                    placeholder="Selecione a foto do diretor"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.files[0])}
                                                    errorMessage={errors.img_director?.message}
                                                    aria-invalid={!!errors.img_director}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className={styles.formGroupItem}>
                                        <Controller
                                            name="birthdate_director"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Input
                                                    label="Data de Nascimento"
                                                    type="date"
                                                    id="birthdate_director"
                                                    placeholder="Selecione a data de nascimento"
                                                    value={value}
                                                    onChange={(e) => onChange(e.target.value)}
                                                    errorMessage={errors.birthdate_director?.message}
                                                    aria-invalid={!!errors.birthdate_director}
                                                />
                                            )}
                                        />
                                        <Controller
                                            name='gender_director'
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <Select errorMessage={errors.gender_director?.message} label='Gênero' id='genero'
                                                    value={value}
                                                    onChange={onChange}
                                                    name='genero'
                                                    placeholder='Selecione o gênero'
                                                    values={generoOptions}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.blockBtn}>
                                    <Button onClick={() => setCurrentStep(1)} type="button">
                                        Voltar
                                    </Button>
                                    <Button type="submit" disabled={isLoading}>
                                        {isLoading ? 'Registrando...' : 'Registrar'}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </section>
                </form>
            </main>
        </>
    );
};

export default EscolaRegister;
