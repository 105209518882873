import styles from './MoreInfoCourse.module.css';
import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { CardMinimalCourseInfo, MenuDrawer, PnoWrap } from "../../components"
import { courseService, GET_DISCIPLINE, Head } from "../../shared"
import { useParams } from "react-router-dom"
import { useAuthContext } from "../../shared/hooks"
import { toast } from "react-toastify"

import { faBookOpen, faCheckCircle, faTimesCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

import Container from '../../components/New/Container';
import TitleSection from '../../components/New/TitleSection.jsx';
import Cards from '../../components/New/Cards.jsx'

export function MoreInfoCourse() {
  const { palette, breakpoints } = useTheme()
  const sm = useMediaQuery(breakpoints.up('sm'))
  const { id: courseId } = useParams();

  const [course, setCourse] = useState(null)
  const [disciplineSelected, setDisciplineSelected] = useState(null)

  const [discipline, setDiscipline] = useState([])

  const [disciplineAccount, setDisciplineAccount] = useState(0);
  const [totalAccount, setTotalAccount] = useState(0);
  const [page, setPage] = useState(1);
  const { token } = useAuthContext()

  const [loading, setLoading] = React.useState(false);
  const [Change, setChange] = React.useState(false);

  const [checked, setChecked] = React.useState([]);
  const [checkedToRemove, setCheckedToRemove] = React.useState([]);
  const [Aux, setAux] = React.useState([]);

  const handleToggle = (value) => () => {
    console.log('entrei', value, { checked });

    let newChecked = [...checked];
    if (newChecked.indexOf(value) === -1) {
      setChecked(newChecked);
      newChecked.push(value);
    } else {
      console.log('entrei\n\ndelete');

      newChecked.splice(newChecked.indexOf(value), 1);
      console.log({ newChecked });
      setChecked(newChecked);

      setCheckedToRemove(old => [...old, value])
    }
  };

  useEffect(() => {
    if (courseId && typeof Number(courseId) === 'number') {
      courseService.getOne(courseId).then((data) => {
        setCourse(data.data)
      }).catch(err => {
        console.log({ err });
      })
    }
  }, [])

  useEffect(() => {
    if (course && course !== null) {
      const getSubjectCourse = async () => {
        try {
          const { url, options } = courseService.getAllCourseJoinDiscipline(course?.name, token)
          const response = await fetch(url, options);
          const result = await response.json();
          setDisciplineSelected(result.data[0])
        } catch (error) {
          toast.error(error.message)
        }
      }
      getSubjectCourse();
    }
  }, [course, Change])

  useEffect(() => {
    if (disciplineSelected && disciplineSelected !== null) {
      if (disciplineSelected && disciplineSelected.subjects?.length > 0) {
        setChecked([]);
        setAux([])
        setDisciplineAccount(disciplineSelected.subjects?.length)
        disciplineSelected.subjects.forEach(dis => {
          setChecked(old => [...old, dis.id])
          setAux(old => [...old, dis.id])
        });
      }
    }
  }, [disciplineSelected, Change, disciplineAccount])

  useEffect(() => {
    const findAllDiscipline = async () => {
      try {
        const { url, options } = GET_DISCIPLINE(page, 5, '', token);
        const response = await fetch(url, options);
        const result = await response.json();
        setDiscipline(result.data);
        console.log({ a: result.data });
        setTotalAccount(result.meta.total)
      } catch (error) {
        // setError(error.message)
      }
    }
    findAllDiscipline();
  }, [page]);


  const joinDisciplineInCourse = async () => {
    setLoading(true)
    let subject_ids = []
    let subject_ids_remove = []

    checkedToRemove.forEach(el => {
      if (Aux.indexOf(el) !== -1) subject_ids_remove.push(el)
    });

    checked.forEach(el => {
      if (Aux.indexOf(el) === -1) subject_ids.push(el)
    });

    if (subject_ids_remove.length > 0) {
      await deleteDisciplineInCourse(subject_ids_remove)
    }


    const response = await courseService.createJoinCourseOfDiscipline({ course_id: courseId, subject_ids }, token)

    if (response instanceof Error || !response || response.message) {
      toast.error(response.message)
      setLoading(false);
    } else {
      // console.log(response.message);
      toast.success('disciplina(s) adiciona(s) com sucesso!', {
        position: 'top-right',
        className: 'custom-toast',
      })
      setChange(old => !old)
      setLoading(false);
    }
  }

  const deleteDisciplineInCourse = async (subject_ids) => {

    const response = await courseService.deleteJoinCourseOfDiscipline({ course_id: courseId, subject_ids })

    if (response instanceof Error || !response) {
      console.log(response);

      toast.error(response.message)
      setLoading(false);
    } else {
      console.log(response);
      toast.error('disciplina(s) removidas(s) com sucesso!', {
        position: 'top-right',
        className: 'custom-toast',
      })
      setChange(old => !old)
      setLoading(false);
    }
  }

  const [activeTab, setActiveTab] = useState('overview');
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <MenuDrawer page={`Dados Relacionados ao Curso`}>


      <Container>

        <TitleSection
          title='Dados Relacionados ao Curso'
          text='Gerencie todas as disciplinas relacionadas ao curso.'
        />

        <Cards
          totalEscolas={totalAccount}
          escolasAtivas={discipline.filter((item) => item.status.name === 'active').length}
          escolasInativas={discipline.filter((item) => item.status.name === 'inactive').length}
          escolasPendentes={discipline.filter((item) => item.status.name === 'pending').length}
          customCards={[

            { title: 'Total de Disciplinas', number: totalAccount, icon: 'faBookOpen', iconColor: '#E19E06' },
            { title: 'Disciplinas Ativas', number: discipline.filter((item) => item.status.name === 'active').length, icon: 'faCheckCircle', iconColor: '#28a745' },
            { title: 'Disciplinas Inativas', number: discipline.filter((item) => item.status.name === 'inactive').length, icon: 'faTimesCircle', iconColor: '#dc3545' },
            { title: 'Pendentes', number: discipline.filter((item) => item.status.name === 'pending').length, icon: 'faHourglassHalf', iconColor: '#ffc107' }
          ]}
        />

        <nav className={styles['detail__tabs']}>
          <button
            className={`${styles['detail__tab-btn']} ${activeTab === 'overview' ? styles['detail__tab-btn--active'] : ''
              }`}
            onClick={() => setActiveTab('overview')}
          >
            Visão Geral
          </button>
          <button
            className={`${styles['detail__tab-btn']} ${activeTab === 'statistics' ? styles['detail__tab-btn--active'] : ''
              }`}
            onClick={() => setActiveTab('statistics')}
          >
            Estatística do curso
          </button>
        </nav>

        {activeTab === 'overview' &&
          (
            <section>
              <h1>
                Visão geral do curso
              </h1>
            </section>
          )}
        {activeTab === 'statistics' &&
          (
            <section>
              <h1>
                Estatística do curso
              </h1>
            </section>
          )}

      </Container>
    </MenuDrawer>
  )
}
