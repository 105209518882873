import React, { useEffect, useMemo, useState } from 'react'
import { MenuDrawer, PnoWrap, Input } from '../../components'
import { Environment, studentServices, Head, inscription_services, schoolService, courseService } from '../../shared'
import {
    Box, Button, Divider, Drawer, Icon, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    TextField,FormControl, InputLabel, Select, MenuItem,
    Typography, useTheme
} from '@mui/material'


import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'


import { useSearchParams, Link } from 'react-router-dom';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { PrivateSession } from '../../shared/hooks/usePermission'


const schema = yup.object({
    user_id: yup.number().required().moreThan(0),
    subject_exam_access_id: yup.number().required().moreThan(0),
    grade: yup.number().required(),
})

export function StudentEnrolled() {
    const { palette } = useTheme();


    const { control, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            user_id: undefined,
            subject_exam_access_id: undefined,
            grade: undefined,
        }
    })

    const userId = watch('user_id')

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [school, setSchool] = React.useState([]);
    const [SearchParmams, setSearchParmams] = useSearchParams({ page: 1 });
    const [UserSelect, setUserSelect] = useState([])

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const { token } = useAuthContext()
    const [totalAccount, setTotalAccount] = React.useState(0);
    const [Change, setChange] = React.useState(false);
    const [discipline, setDiscipline] = React.useState([]);

    const search = useMemo(() => {
        return SearchParmams?.get('search') || ''
    }, [SearchParmams])

    const page = useMemo(() => {
        return SearchParmams?.get('page') || 1
    }, [SearchParmams])

    const deletestudent = async (id) => {
        try {
            await studentServices.delOne(id);
            alert('Alunos eliminado com sucesso')
        } catch (error) {
            console.log(error);
        }
    }

    const findAllSchools = async () => {
        try {
            const { url, options } = schoolService.GET_school(undefined, 1000000000, '', token ?? '')
            const response = await fetch(url, options)
            const result = await response.json();
            console.log({ result });

            setSchool(result.data);
        } catch (error) {
            console.log({ error });
        }
    }

    useEffect(() => {
        findAllSchools()
    }, [])


    React.useEffect(() => {
        const findAllCandidate = async () => {
            try {
                setLoading(true);
                const response = await inscription_services.getAllCandidate(page, Environment.ROW_LIMIT, search ?? '', token ?? '');
                console.log({ response });

                setData(response?.data ?? []);

                setTotalAccount(response?.meta.total)
            } catch (error) {
                if (error.message === 'Failed to fetch')
                    toast.error('Problemas de conexão com a internet!')
                toast.error(error.message)

            } finally {
                setLoading(false);
            }
        }
        findAllCandidate();
        setChange(false)
    }, [search, page, Change]);

    const handleSubmitNote = async (data) => {
        try {
            const result = await courseService.post_user_discipline_note(data, token);

            console.log({data, result});
            if(result?.status === 404) 
                return toast.error('Problemas ao salvar a nota');

            toast.success(result?.message??'Nota atribuida com sucesso')
            setChange(true)
        } catch (error) {
            console.error({ error })
            return toast.error(error?.message);
            
        }
    }

    const handleSubmitNoteUpdate = async (data) => {
        try {
            const result = await courseService.post_user_discipline_note(data, token);

            console.log({data, result});
            toast.success(result?.message??'Nota atribuida com sucesso')

        } catch (error) {
            console.error({ error })
            return toast.error(error?.message);
            
        }
    }


    // const handleSetMedia = async () => {
    //     try {
    //         const result = await courseService.set_media_all_candidate( token);

    //         console.log({result});
    //         toast.success(result?.mensagem)

    //     } catch (error) {
    //         console.error({ error })
    //         return toast.error(error?.message);
            
    //     }
    // }

    const setNoteStudent = async (average, user_id) => {
        try {
            const result = await inscription_services.setNote({ average, user_id }, token)

            console.log(result);

            if (result.data) {
                toast.success('Nota atribuida com sucesso')

                setChange(true)
                return;
            }
            return toast.error('Problemas ao atribuir a nota!');
        } catch (error) {
            console.error({ error })
            return toast.error('Problemas ao atribuir a nota!');
        }
    }

    const manualSelection = async (user_id) => {
        const userSelected = data[data.findIndex(el => el.id === user_id)]
        console.log({ userSelected });
        if (!userSelected.media_exam_access?.average) return toast.error('Problemas ao selecionar. Aluno sem nota Atribuida')

        try {
            const result = await inscription_services.manualSelect(user_id, token);

            console.log(result);

            if (result.mensagem === 'Candidatos selecionados com sucesso') {
                toast.success(userSelected.name + ' selecionado com sucesso!!!')

                setChange(true)
                return;
            }
            // return toast.error('Problemas ao Selecionar o aluno!');

        } catch (error) {
            console.log(error);
            return toast.error('Problemas ao Selecionar o aluno!');
        }
    }

    const automaticSelection = async (user_id) => {

        try {
            const result = await inscription_services.automaticSelect(token);

            console.log(result);
            if (result.original) {

                return toast.error(result.original.message);
            }


            if (result.mensagem === 'Candidatos selecionados com sucesso') {
                toast.success('Seleção automatica realizada com sucesso!!!')

                setChange(true)
                return;
            }

            // return toast.error('Problemas ao Selecionar os alunos!');

        } catch (error) {
            console.log(error);
            return toast.error('Problemas ao Selecionar os alunos!');
        }
    }

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer)
    }

    const getDisciplineAF = async (id, user_id) => {
        setValue('user_id', user_id)
        setUserSelect()
        try {
            const discipline_response = await courseService.get_TRAINING_discipline(id, token);
            console.log({discipline_response});
            setDiscipline(discipline_response.data[0]?.subject_exam_accesses??[])
        } catch (error) {
            console.log({error});
            toast.error(error?.message)
        }
    }

    return (
        <MenuDrawer page='Candidatos Inscritos'>
            <Head title='Candidatos Inscritos' />
            <PrivateSession menu={["candidate_inscri_TM"]} permissionRoute={3}>
            <Drawer anchor='right' open={openDrawer} variant='temporary' >  
                <Box width={300} pt={10} px={2} mb={4} >
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <PnoWrap color='black' text='Registrar Notas de exames' />
        
                        <Icon onClick={() => toggleDrawer()}>close</Icon>
                    </Box>


                    <Box mt={2}>

                        <PnoWrap color='gray' text={'Dados pessoais'} sx={{mb:2}}/>
                        <p>
                            <small >
                                Nome completo do inscrito
                            </small>
                        </p>
                        <strong >
                            {userId && data[data.findIndex(per => per.id === Number(userId))]?.name}
                        </strong>
                    </Box>
                    <Box >
                        <p>
                            <small >
                                Número de Idêntidade
                            </small>
                        </p>
                        <strong >
                            {userId && data[data.findIndex(per => per.id === Number(userId))]?.document?.value}
                        </strong>
                    </Box>
                    <Box >
                        <p>
                            <small >
                                Curso
                            </small>
                        </p>
                        <strong >
                            {userId && data[data.findIndex(per => per.id === Number(userId))]?.application?.courses?.name}
                        </strong>
                    </Box>
                    <Divider sx={{mb:2}}/>
                    <PnoWrap color='gray' text={'Disciplinas & Notas'} sx={{mb:2}}/>
                  
                    <Box component={'form'} noValidate onSubmit={handleSubmit(handleSubmitNote)}>
                        <Controller
                            control={control}
                            name='subject_exam_access_id'
                            render={({field: {value, onChange}}) => (
                                <Box display={'flex'} flexDirection={'column'} sx={{my:2}}>
                                    {/* <FormLabel>Nacionalidade</FormLabel> */}
    
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Selecione o Disciplina</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={value??''}
                                            label="Selecione o Disciplina"
                                            onChange={onChange}
                                            fullWidth
                                            sx={{minWidth: 250}}
                                        >
                                            {discipline.length > 0 && discipline.map(el => (<MenuItem key={el.id} value={el?.subject?.id}>{el?.subject?.name}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}
                        />
                        <Box display={'flex'} justifyContent={'space-around'} gap={2}>
                            <Box>
                            <Controller
                                control={control}
                                name='grade'
                                render={({field: {value, onChange}}) => (
                                    <Input label={'digite a nota'}
                                    type={'number'} errorText={''}
                                    error={false} value={value??''}
                                    onChange={onChange}/>
                                )}
                            />
                            </Box>
    
                            <Button variant='contained' type='submit' color='secondary' sx={{mb:2}}>
                                Actualizar
                            </Button>
                        </Box>
                    </Box>

                    <Divider sx={{mb:2}}/>

                    {discipline.length > 0 && discipline.map((dis, index) => (
                        <Box key={dis.id}>
                        <p>
                            <small >
                                {dis?.subject?.name}
                            </small>
                        </p>
                        <strong >
                            Nota:  
                            &nbsp;{
                            userId 
                            && data[data.findIndex(per => per.id === Number(userId))]?.exam_grade_access[data[data.findIndex(per => per.id === Number(userId))]?.exam_grade_access.findIndex(di => di.subject_exam_access_id === Number(dis.id))]?.grade||'não lançado os'}
                            &nbsp;valores
                        </strong>
                    </Box>
                    ))}


                </Box>
            </Drawer>
            </PrivateSession>

            <Box component={Paper} py={2} m={2} flex={1}>
                <Box m={1} ml={2}>
                    <section className='bloco-pesquisa-botao'>
                        <div className='search'>
                            <input type='text' placeholder='Pesquisar' />
                            <button><i className='fas fa-search'></i></button>
                        </div>

                        <PrivateSession menu={['candidate_inscri_TM']} permissionRoute={3} >
                            <button onClick={() => automaticSelection()} className='btn-select'>
                                Seleção Automatica <i className='fas fa-circle-check icon'></i>
                            </button>
                        </PrivateSession>
                    </section>
                </Box>

                <PrivateSession menu={['candidate_inscri_TM']} permissionRoute={1}>
                    <Box m={1} ml={2}>
                        <Box marginBottom={5}>
                            <Box p={1} display={'flex'} width={'auto'} justifyContent={'flex-end'}>
                                <TableContainer component={Paper} elevation={3} sx={{ flexGrow: 1 }} >
                                    <Table sx={{ minWidth: 650 }} size='small' aria-label="a dense table">
                                        <TableHead sx={{ backgroundColor: `${palette.primary.main}`, '&:last-child td': { color: '#333' } }}>
                                            <TableRow>
                                                <TableCell className='title-table text-center'>B.I.</TableCell>
                                                <TableCell className='title-table text-center'>Nome</TableCell>
                                                <TableCell className='title-table text-center'>Escola</TableCell>
                                                <TableCell className='title-table text-center'>Curso</TableCell>
                                                <TableCell className='title-table text-center'>Média</TableCell>
                                                <TableCell className='title-table text-center'>Resultado</TableCell>
                                                <TableCell className='title-table text-center'>Acções</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.length > 0 &&
                                                data.map((item, index) => (
                                                    <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell className='text-center'>{item?.document.value}</TableCell>
                                                        <TableCell>{item?.name}</TableCell>
                                                        <TableCell className='text-center'>
                                                            <PnoWrap color='black' text={school[school.findIndex(el => el?.id === item?.application?.locations?.id)]?.name} />
                                                        </TableCell>
                                                        <TableCell className='text-center'>{item?.application.courses.name}</TableCell>
                                                        <TableCell className='text-center'>
                                                            <PrivateSession menu={['candidate_inscri_TM']} permissionRoute={3}>
                                                                <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                                    <button className='btn-icon-table' onClick={() => {toggleDrawer(); getDisciplineAF(item?.application?.courses?.training_area_id, item.id) }} >
                                                                        Lançar Nota
                                                                    </button>
                                                                </Box>
                                                            </PrivateSession>
                                                        </TableCell>
                                                        <TableCell className='text-center'>
                                                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                                {item.media_exam_access.average}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Box display={'flex'} gap={1} justifyContent={'center'}>
                                                                <PrivateSession menu={['candidate_inscri_TM']} permissionRoute={4}>
                                                                    <Link className='btn-icon' to={`/people/students/show/${item.id}`}>
                                                                        <i className="fa-solid fa-eye"></i>
                                                                    </Link>
                                                                </PrivateSession>
                                                                <PrivateSession menu={['candidate_inscri_TM']} permissionRoute={3}>
                                                                    <button className='btn-icon' onClick={() => manualSelection(item.id)} title={`Selecionar o(a) candidato(a) ${item.name}`}>
                                                                        <i className="fas fa-check"></i>
                                                                    </button>
                                                                </PrivateSession>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                            {loading && (
                                                <TableRow>
                                                    <TableCell colSpan={10} align="center"><LinearProgress color='secondary' /></TableCell>
                                                </TableRow>)
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            {data.length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={12} align="center"><Typography width={250}>Lista de produtos vazia</Typography></TableCell>
                                                </TableRow>
                                            )}
                                            <TableRow>
                                                <TableCell colSpan={10} align="center" sx={{ pb: 2 }}>
                                                    <Pagination
                                                        count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                                        page={Number(page)} onChange={(_, newPage) => { setSearchParmams({ _, page: newPage.toString() }, { replace: true }) }}
                                                        color='primary'
                                                        size='small'
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </PrivateSession>

            </Box>

        </MenuDrawer>
    )
}
