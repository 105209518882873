import React, { useEffect, useMemo, useState } from 'react'
import { MenuDrawer } from '../../components'
import { Environment, Head } from '../../shared'
import {
    Box, LinearProgress, Pagination,
    Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow,
    Typography, useTheme
} from '@mui/material'

import { Link, useSearchParams } from 'react-router-dom';
import { inscription_services, schoolService } from '../../shared/services';
import { useAuthContext } from '../../shared/hooks';
import { toast } from 'react-toastify';
import Select from '../../components/formComponents/Select';

import Cards from '../../components/New/Cards.jsx'
import SchoolModal from '../../components/Escola/Modal.jsx'
import TitleSection from '../../components/New/TitleSection.jsx';
import Container from '../../components/New/Container.jsx';
import Toolbar from '../../components/New/Toolbar.jsx';

import { faCheckCircle, faClock, faPauseCircle, faSchool } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

export function Escola() {
    const { palette } = useTheme();
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [totalAccount, setTotalAccount] = React.useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const { token } = useAuthContext()
    const [Change, setChange] = React.useState(false);
    const [schoolType, setSchoolType] = useState([]);
    const [province, setProvince] = useState([]);
    const [status, setStatus] = useState([]);

    const search = useMemo(() => {
        return searchParams?.get('search') || ''
    }, [searchParams])

    const page = useMemo(() => {
        return searchParams?.get('page') || 1
    }, [searchParams])

    const schoolTypeSelect = useMemo(() => {
        return searchParams?.get('SchoolType') || ''
    }, [searchParams])


    const ProvinceSelect = useMemo(() => {
        return searchParams?.get('province') || ''
    }, [searchParams])

    const statusSelect = useMemo(() => {
        return searchParams?.get('status') || ''
    }, [searchParams])

    const getSchoolType = async () => {
        try {
            const response = await schoolService.listSchoolType();
            setSchoolType(response.data)
        } catch (error) {
            console.log({ error });
        }
    }

    const findAllSchools = async () => {
        try {
            setLoading(true);
            setError(null);
            const { url, options } = schoolService.GET_school(page, Environment.ROW_LIMIT, search, schoolTypeSelect, statusSelect, ProvinceSelect, token ?? '')


            const response = await fetch(url, options)
            const result = await response.json();

            console.log('====================================');
            console.log({ result });
            console.log('====================================');
            setData(result.data);
            setTotalAccount(result.meta.total)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }


    const handleSelectProvince = async () => {
        try {
            const { data } = await inscription_services.getProvinceForCountry(6);
            setProvince(data);
        } catch (error) {
            console.log(error);
        }
    }

    const getAllStatus = async () => {
        try {
            const { data } = await schoolService.getStatusSchool('', '');
            setStatus(data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getSchoolType();
        handleSelectProvince();
        getAllStatus()
    }, [])

    useEffect(() => {
        findAllSchools();
    }, [search, page, Change, schoolTypeSelect, ProvinceSelect, statusSelect])

    const DeleteSchool = async (id) => {
        setLoading(true);
        setError(null);
        const response = await schoolService.DeleteOne(id, token);
        if (response instanceof Error) {
            console.log(response)
            setError(response.message);
            toast.error(response.message)
            setLoading(false);
        } else {
            toast.error('eliminado com sucesso!', {
                position: 'top-right',
                className: 'custom-toast',
            })
            setChange(!Change)
            setLoading(false);
        }
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('add');
    const [selectedSchool, setSelectedSchool] = useState(null);

    const handleOpenModal = (mode, school = null) => {
        setModalMode(mode);
        setSelectedSchool(school);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSubmit = () => {
        handleCloseModal();
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Ativo':
                return '#28a745';
            case 'Inactivo':
                return '#dc3545';
            case 'Pendente':
                return '#ffc107';
            default:
                return '#000';
        }
    };

    return (
        <MenuDrawer page='Escolas Registadas'>
            <Head title='Escolas Registadas' />

            <Container>
                <TitleSection
                    title='Escolas'
                    text='Gerencie todas as escolas cadastradas no sistema.'
                />

                <Cards
                    totalEscolas={totalAccount}
                    escolasAtivas={data.filter((item) => item.status.name === 'active').length}
                    escolasInativas={data.filter((item) => item.status.name === 'inactive').length}
                    escolasPendentes={data.filter((item) => item.status.name === 'pending').length}
                    customCards={[
                        { title: 'Total de Escolas', number: totalAccount, icon: faSchool, iconColor: '#E19E06' },
                        { title: 'Escolas Ativas', number: data.filter((item) => item.status.name === 'active').length, icon: faCheckCircle, iconColor: '#28a745' },
                        { title: 'Escolas Inativas', number: data.filter((item) => item.status.name === 'inactive').length, icon: faPauseCircle, iconColor: '#dc3545' },
                        { title: 'Pendentes', number: data.filter((item) => item.status.name === 'pending').length, icon: faClock, iconColor: '#ffc107' }
                    ]}
                />

                <Toolbar
                    search={search}
                    onSearchChange={(evt) => setSearchParams({ search: evt.target.value }, { replace: true })}
                    onAddClick={() => handleOpenModal('add')}
                    onFilterClick={() => handleOpenModal('filter')}
                    onRecycleClick={() => handleOpenModal('recycle')}
                    disableRecycle={data.length === 0}
                    searchPlaceholder="Pesquisar por nome, número ou director, email, etc..."
                />

                <TableContainer
                    component={Paper}
                    elevation={3}
                    sx={{
                        flexGrow: 1,
                        borderRadius: 2,
                        overflow: 'hidden',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                    }}
                >
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="Tabela de Escolas">
                        <TableHead sx={{ backgroundColor: palette.primary.main }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Número</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Nome</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Tipo de Escola</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Director</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Estado</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'left', color: '#fff' }}>Acções</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length > 0 ? (
                                data.map((item) => (
                                    <TableRow
                                        key={item.id}
                                        sx={{
                                            '&:last-child td, &:last-child th': { border: 0 },
                                            backgroundColor: 'white',
                                            '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' },
                                            transition: 'background-color 0.3s',
                                            '&:hover': { backgroundColor: '#f1f1f1' }
                                        }}
                                    >
                                        <TableCell sx={{ textAlign: 'left' }}>{item.number}</TableCell>
                                        <TableCell sx={{ textAlign: 'left' }}>{item.name}</TableCell>
                                        <TableCell sx={{ textAlign: 'left' }}>{item.email}</TableCell>
                                        <TableCell sx={{ textAlign: 'left' }}>
                                            {item?.principal_user[0]?.name ?? 'Não Informado'}
                                        </TableCell>
                                        <TableCell 
                                        sx={{ textAlign: 'left', color: getStatusColor(item.status.name) }}>
                                            {item.status.name}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'left' }}>
                                            <Box display="flex" gap={1} justifyContent="flex-start">
                                                <Link className="btn-icon"   to={`/academic/school/show/${item.id}`}>
                                                    <i className="fa-solid fa-eye"></i>
                                                </Link>
                                                <Link to={`/academic/school/update/${item.id}`}>
                                                    <Typography className="btn-icon btn-edit">
                                                        <i className="fa-solid fa-edit"></i>
                                                    </Typography>
                                                </Link>
                                                <Typography
                                                    className="btn-icon btn-delete"
                                                    onClick={() => {
                                                        if (
                                                            window.confirm('Tem certeza de que deseja excluir esta escola?')
                                                        ) {
                                                            DeleteSchool(item.id);
                                                        }
                                                    }}
                                                >
                                                    <i className="fa-solid fa-trash"></i>
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <Typography width={250}>Lista de Escolas vazia</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {loading && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <LinearProgress color="secondary" />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={6} align="center" sx={{ pb: 2 }}>
                                    <Pagination
                                        count={Math.ceil(totalAccount / Environment.ROW_LIMIT)}
                                        page={Number(page)}
                                        onChange={(_, newPage) =>
                                            setSearchParams({ page: newPage.toString() }, { replace: true })
                                        }
                                        color="primary"
                                        size="small"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>


                

            </Container>
        </MenuDrawer>
    )
}
