// styles
import styles from './Inscription.module.css'
import styless from '../../components/formComponents/Select.module.css'
// dependencies
import React, { useEffect, useMemo, useState } from 'react'
import { inscription_services } from '../../shared'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useLocalData } from '../../shared/hooks'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { SendNIf } from './components_2/SendNIf'
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, FormControl, FormLabel, Grid, Icon, InputLabel, MenuItem, Typography } from '@mui/material'
import { PnoWrap } from '../../components'
// components
import Header from '../../components/Header'
import Input from '../../components/formComponents/Input'
import Select from '../../components/formComponents/Select'

const schema = yup.object().shape({
    full_name: yup.string().required('Nome Completo obrigatorio').min(6, 'Nome Completo deve ter no minimo 6 caracteres'),
    nif: yup.string().required('numero do documento de identidade obrigatório'),
    birth_date: yup.string().required('Informe sua data de nascimento'),
    gender: yup.string().oneOf(['M', 'F']).required('Genêro obrigatório'),
    country_id: yup.number().required('País é obrigatorio'),
    province_id: yup.number().required('Província é obrigatorio'),
    natural_from: yup.string().optional(),
    debilities: yup.number().optional(),
    whereNoneClass: yup.boolean().optional(),
    last_school_type_id: yup.number().optional(),
    last_school_name: yup.string().required('Nome da escola onde frequentou a 9º classe obrigatorio').min(3, 'minimo de caracteres 3'),
    final_score: yup.number().required('Informe a sua média ou nota final')
    .max(20, 'a média não deve ser maior que 20'),
    last_school_country_id: yup.number().required('Informe o país em que frequêntou.'),
    last_school_province_id: yup.number().required('Informe a província ou cidade em que frequêntou.'),
    last_school_end_year: yup.number().required('Informe o ano em que concluíu a 9º classe'),
    last_school_city: yup.string().matches(/^[a-zA-Z]*$/, 'Este campo ñ recebe valor numerico').required('Em que minícipio situava a sua anterior escola'),
    debilities_description: yup.string().optional(),
    // location: yup.string().required('Campo obrigatorio').min(4, 'Localição deve ter no minimo 4 caracteres'),
})


const def = ['Visual',
    'Auditivo',
    'Fisico Motora',
    'Intelectual',
    'Surdo/ Cegueira',
    'Hiperativo',
    'Multiplas',
    'TDG Autista',
    'Carência Afectiva',
    'Altas Habilidades',
    'Transtornos N. Especificados',
    'D. Aprendizagem',
    'Sindrome-Dawn',
    'Transtorno da Fala',
    'Outros']



const Inscription = () => {

    const { control, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
        resolver: yupResolver(schema), defaultValues: {
            full_name: "",
            last_school_name: '',
            birth_date: "",
            gender: "",
            country_id: undefined,
            province_id: undefined,
            nif: '',
            natural_from: '',
            final_score: undefined,
            last_school_city: '',
            debilities: 1,
            whereNoneClass: false,
            last_school_end_year: undefined,
        }
    });

    const seeDebilite = watch('debilities')

    const { setCandidateLocalData, getCandidateLocalData } = useLocalData();
    const navigate = useNavigate()

    const [user, setUser] = useState(null);
    const [userLocal, setUserLocal] = useState(null);
    const [BI, setBI] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [OpenModalNif, setOpenModalNif] = useState(true);
    const [countries, setCountries] = useState([]);
    const [province, setProvince] = useState([]);
    const [province2, setProvince2] = useState([]);
    const [last_school_type_id, setlast_school_type_id] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const doc = useMemo(() => {
        return searchParams?.get('doc')
    }, [])

    
    useEffect(() => {
        window.addEventListener('popstate', (evt) => {
            // alert('Maaaaaaaaaaaaaa', evt);
            navigate('/inscription')
        })

    }, [])

    useEffect(() => {
        const dataLocal = getCandidateLocalData()

        if (!userLocal && JSON.parse(dataLocal)) {
            setUserLocal(JSON.parse(dataLocal))
        }
    }, [userLocal]);

    const handleSetNif = async () => {
        setLoading(true)

        setValue('nif', BI ?? '');
        try {
            const result = await fetch(`https://consulta.edgarsingui.ao/consultar/${BI}/bilhete`);
            const response = await result.json()

            if (!response?.error) {
                setUser(response);
            }
        } catch (error) {
            console.log('err=> ', error);

        } finally {
            setOpenModalNif(false)
            setLoading(false)
        }
    }

    const submit = (data) => {
        setLoading(true);
        setCandidateLocalData({ ...data, doc });
        setLoading(false)
        navigate('/inscription/two')
    }

    useEffect(() => {
        if (userLocal) {
            setSearchParams({ doc: userLocal.doc }, { replace: false })
            setOpenModalNif(false)
            setValue('full_name', userLocal.full_name);
            setValue('birth_date', userLocal.birth_date);
            setValue('nif', userLocal.nif);
            setValue('gender', userLocal.gender);
            setValue('country_id', userLocal.country_id);
            setValue('debilities', userLocal.debilities);
            setValue('final_score', userLocal.final_score);
            setValue('natural_from', userLocal.natural_from);

            handleSelectCountry(userLocal.country_id)
            handleSelectCountry(userLocal.last_school_country_id, 2)
            setValue('province_id', userLocal.province_id);
            setValue('last_school_city', userLocal.last_school_city);
            setValue('last_school_name', userLocal.last_school_name);
            setValue('last_school_type_id', userLocal.last_school_type_id);
            setValue('last_school_country_id', userLocal.last_school_country_id);
            setValue('last_school_end_year', userLocal.last_school_end_year);
            setValue('last_school_province_id', userLocal.last_school_province_id);
            setValue('last_school_country_id', userLocal.last_school_country_id)
        }
    }, [userLocal]);

    useEffect(() => {
        if (user) {
            setValue('full_name', user.name);
            setValue('birth_date', user.data_de_nascimento);
            setValue('nif', BI ?? '');
            setValue('gender', user.gender);
            setValue('country_id', user.country_id);
            if (doc && Number(doc) === 1) {
                setValue('country_id', getIdCountryNacional()?? undefined)
                setValue('last_school_country_id', getIdCountryNacional() ?? undefined)
                handleSelectCountry(getValues('country_id'))
                handleSelectCountry(getValues('last_school_country_id'), 2)
            }
            
            if (doc &&  doc === '2') {
                setCountries(countries.filter(el => el.id !== 6));
            }
            
            // setValue('last_school_country_id', getIdCountryNacional() ?? undefined)
            // handleSelectCountry(getValues('last_school_country_id'), 2)
        }
    }, [user]);

    useEffect(() => {
        if (!getValues('whereNoneClass')) {
            handleSelectCountry(1, 2)
        }
        if (doc === '1') {
            handleSelectCountry(6, 1)
        }

    }, [])

    const getCountries = async () => {
        try {
            const { data } = await inscription_services.getAllCountry();
            let cleanData = data ?? [];
            
            if(doc !== 1) {
                cleanData = data.filter(el => el.name !== 'Angola')
            }
            setCountries(cleanData)
        } catch (error) {
            console.log(error);
        }
    }

    const getlast_school_type_id = async () => {
        try {
            const { data } = await inscription_services.getSchoolType();
            setlast_school_type_id(data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSelectCountry = async (id, control = 1) => {
        if (control === 1) {
            setValue('country_id', id ?? '')
            try {
                const { data } = await inscription_services.getProvinceForCountry(id);
                setProvince(data);
            } catch (error) {
                console.log(error);
            }
        } else {
            setValue('last_school_country_id', id ?? '');

            if (id && id !== '6') setValue('whereNoneClass', true);
            if (id === '6') setValue('whereNoneClass', false);
            if (!id) {
                console.log(getValues('whereNoneClass'));
                if (getValues('whereNoneClass')) {
                    setValue('last_school_country_id', 6);
                    id = 6
                } else {
                    id = 1
                }
            }

            try {
                const { data } = await inscription_services.getProvinceForCountry(id);
                setProvince2(data)
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        getCountries()
        getlast_school_type_id()
    }, [])

    const getIdCountryNacional = () => {
        return countries[countries.findIndex(el => el.name === 'Angola')]?.id;
    }

    return (
        <section style={{ background: '#f5f5f5 !importatnt' }}>
            <Header />
            {OpenModalNif ? <SendNIf nif={BI} loading={Loading} setClose={handleSetNif} onChangeNif={setBI} /> :
                <section className={styles.inscription}>
                    <div className={styles.card}>
                        <p className={styles.paragraph}>Preencha todos os campos para fazer a inscrição</p>
                        <div className={styles.blockForm}>
                            <Box component={'form'} noValidate encType='multipart/form-data' onSubmit={handleSubmit(submit)}>
                                <h4 className={styles.subtitle}>Informações Pessoais</h4>
                                <div className={styles.row}>
                                    <div className={`${styles.blockInput} ${styles.firstInput}`}>
                                        <Controller
                                            control={control}
                                            className={styles.input}
                                            name='full_name'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth>
                                                    <Input fullWidth
                                                        label='Nome Completo'
                                                        placeholder='Digite seu nome'
                                                        error={!!errors.full_name?.message}
                                                        errorText={errors.full_name?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                    />
                                                    {errors.full_name?.message && <Typography color={'red'} fontSize={12}>{errors.full_name?.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </div>
                                    <div className={`${styles.blockInput} ${styles.lastInput}`}>
                                        <Controller
                                            control={control}
                                            name='birth_date'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth>
                                                    <Input
                                                        fullWidth
                                                        label='Data de Nascimento'
                                                        type={'date'}
                                                        placeholder='Digite seu nº de B.I ou Passaporte'
                                                        error={!!errors.birth_date?.message}
                                                        errorText={errors.birth_date?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                    />
                                                    {errors.birth_date?.message && <Typography color={'red'} fontSize={12}>{errors.birth_date?.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* fim de linha */}

                                <div className={styles.row}>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='gender'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <div className={styless.wrapper}>
                                                            <label htmlFor={'gender'}>Selecione seu gênero</label>
                                                            <select id={'gender'} name={'gender'} onChange={onChange} value={value}>
                                                                <option>Selecione uma opção</option>
                                                                <option value={'M'}>Masculino</option>
                                                                <option value={'F'}>Femenino</option>
                                                            </select>
                                                        </div>
                                                        {errors.gender?.message && <Typography color={'red'} fontSize={12}>{errors.gender?.message}</Typography>}
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                    {doc !== '1' && <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='country_id'
                                            render={({ field: { value } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Selecione o País"
                                                            onChange={(evt) => handleSelectCountry(evt.target.value)}
                                                            fullWidth
                                                            values={countries}
                                                        ></Select>
                                                        {errors.country_id?.message && <Typography color={'red'} fontSize={12}>{errors.country_id?.message}</Typography>}
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>}
                                </div>
                                {/* fim de linha */}

                                <div className={styles.row}>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='province_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={value ?? ''}
                                                        label="Selecione a Província"
                                                        onChange={onChange}
                                                        fullWidt
                                                        values={province}
                                                    />
                                                    {errors.province_id?.message &&
                                                        <Typography color={'red'} fontSize={12}>
                                                            {errors.province_id?.message}
                                                        </Typography>
                                                    }
                                                </Box>
                                            )}
                                        />
                                    </div>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='natural_from'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <Input fullWidth
                                                            label={'Naturalidade'}
                                                            type={'text'}
                                                            placeholder='Digite a sua naturalidade'
                                                            error={!!errors.natural_from?.message}
                                                            errorText={errors.natural_from?.message}
                                                            value={value ?? ''}
                                                            onChange={onChange}
                                                        />
                                                        {errors.natural_from?.message && <Typography color={'red'} fontSize={12}>{errors.natural_from?.message}</Typography>}
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* fim de linha */}

                                <div className={styles.row}>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='debilities'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <div className={styless.wrapper}>
                                                            <label htmlFor={'gender'}>Port. Deficiência</label>
                                                            <select id={'gender'} name={'gender'} onChange={onChange} value={value}>
                                                                <option>Selecione uma opção</option>
                                                                <option value={2}>Sim</option>
                                                                <option value={1}>Não</option>
                                                            </select>
                                                        </div>
                                                        {errors.debilities?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.debilities?.message}
                                                            </Typography>
                                                        }
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                    <div className={styles.blockInput}>
                                        {seeDebilite === '2' &&
                                            <Controller
                                                control={control}
                                                name='debilities_description'
                                                render={({ field: { value, onChange } }) => (
                                                    <FormControl fullWidth>
                                                        <Select fullWidth
                                                            label='Sobre a Deficiência'
                                                            placeholder='Descreva sua deficiência'
                                                            error={!!errors.debilities_description?.message}
                                                            errorText={errors.debilities_description?.message}
                                                            value={value ?? ''}
                                                            onChange={onChange}
                                                            values={def.map((el, id) => ({ id, name: el }))}
                                                        />
                                                        {errors.debilities_description?.message && <Typography color={'red'} fontSize={12}>{errors.debilities_description?.message}</Typography>}
                                                    </FormControl>
                                                )}
                                            />
                                        }
                                    </div>
                                </div>
                                {/* fim de linha */}

                                <h4 className={styles.subtitle}>Informações Academicas</h4>

                                <div className={styles.row}>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='whereNoneClass'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <div className={styless.wrapper}>
                                                            <label htmlFor={'gender'}><PnoWrap color='grayText' text="Onde frequêntou a 9º classe" /></label>
                                                            <select id={'gender'} onChange={(e) => { onChange(e.target.value); handleSelectCountry(undefined, 2) }} value={value} name={'gender'}>
                                                                <option>Selecione uma opção</option>
                                                                <option value={false}>Nacional</option>
                                                                <option value={true}>Exterior</option>
                                                            </select>
                                                        </div>
                                                        {errors.whereNoneClass?.message && <Typography color={'red'} fontSize={12}>{errors.whereNoneClass?.message}</Typography>}
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='last_school_type_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Tipo de escola em que frequentou"
                                                            defaultValue='Publica'
                                                            onChange={onChange}
                                                            fullWidth
                                                            values={last_school_type_id}
                                                        />
                                                        {errors.last_school_type_id?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_type_id?.message}</Typography>}
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* fim de linha */}

                                <div className={styles.row}>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='last_school_name'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth width={'100%'}>
                                                    <Input fullWidth
                                                        placeholder='Digite o nome da escola'
                                                        label={'Nome da escola em que frequentou'}
                                                        error={!!errors.last_school_name?.message}
                                                        errorText={errors.last_school_name?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                    />
                                                    {errors.last_school_name?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_name?.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </div>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='final_score'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth width={'100%'}>
                                                    <Input fullWidth
                                                        placeholder='Digite o nota final'
                                                        label={'Média final que teve'}
                                                        error={!!errors.final_score?.message}
                                                        errorText={errors.final_score?.message}
                                                        value={value ?? ''}
                                                        onChange={onChange}
                                                        type="number"
                                                    />
                                                    {errors.final_score?.message && <Typography color={'red'} fontSize={12}>{errors.final_score?.message}</Typography>}
                                                </FormControl>
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* fim de linha */}

                                <div className={styles.row}>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='last_school_country_id'
                                            render={({ field: { value } }) => (
                                                <Box display={'flex'} flexDirection={'column'} >
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="País em que frequentou"
                                                            onChange={(evt) => handleSelectCountry(evt.target.value, 2)}
                                                            fullWidth
                                                            values={countries}
                                                        >
                                                        </Select>
                                                        {errors.last_school_country_id?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_country_id?.message}</Typography>}

                                                    </FormControl></Box>
                                            )}
                                        />
                                    </div>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='last_school_province_id'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={value ?? ''}
                                                            label="Província em que frequentou"
                                                            onChange={onChange}
                                                            values={province2}
                                                            fullWidth
                                                        ></Select>
                                                        {errors.last_school_province_id?.message &&
                                                            <Typography color={'red'} fontSize={12}>
                                                                {errors.last_school_province_id?.message}
                                                            </Typography>
                                                        }
                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* fim de linha */}

                                <div className={styles.row}>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='last_school_city'
                                            render={({ field: { value, onChange } }) => (
                                                <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                                                    <FormControl fullWidth>
                                                        <Input fullWidth
                                                            type={'text'}
                                                            label={'Munícipio em que frequentou'}
                                                            placeholder='Digite onde situava'
                                                            error={!!errors.last_school_city?.message}
                                                            errorText={errors.last_school_city?.message}
                                                            value={value ?? ''}
                                                            onChange={onChange}
                                                        />
                                                        {errors.last_school_city?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_city?.message}</Typography>}

                                                    </FormControl>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                    <div className={styles.blockInput}>
                                        <Controller
                                            control={control}
                                            name='last_school_end_year'
                                            render={({ field: { value, onChange } }) => (
                                                <FormControl fullWidth style={{ width: '100%' }}>
                                                    <Input fullWidth
                                                        label={'Ano de conclusão'}
                                                        placeholder='Digite o ano conclusão'
                                                        error={!!errors.last_school_end_year?.message}
                                                        errorText={errors.last_school_end_year?.message}
                                                        value={value ?? ''}
                                                        type="number"
                                                        onChange={onChange}
                                                    />
                                                    {errors.last_school_end_year?.message && <Typography color={'red'} fontSize={12}>{errors.last_school_end_year?.message}</Typography>}

                                                </FormControl>
                                            )}
                                        />
                                    </div>
                                </div>
                                {/* fim de linha */}

                                <div className={styles.blockButton}>
                                    <Button
                                        className={styles.button}
                                        type='submit'
                                        disabled={Loading}
                                        onSubmit={handleSubmit(submit)}
                                        endIcon={
                                            Loading ?
                                                <CircularProgress size={'1rem'} /> :
                                                <Icon>save</Icon>
                                        }
                                    >
                                        Continuar
                                    </Button>
                                </div>
                            </Box>
                        </div>
                    </div>
                </section>
            }
        </section>
    )
}

export default Inscription
